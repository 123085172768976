@font-face {
  font-family: 'Paladins';
  src: local('Paladins'), url(../public/PaladinsStraight.woff) format('woff');
}

.App {
  text-align: center;
  font-family: Paladins;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main {
  padding: 20px;
  margin-bottom: 50px;
  background-color: #272828;
}

.inputForm{
  padding: 20px;
}

.imageGrid {
  margin: 5px;
  border: 1px solid #ccc;
  float: left;
}
.imageGrid:hover {
  border: 2px solid #777;
}

.imageGrid img {
  width: 100%;
  height: auto;
}
.imageGrid span {
  display: block;
  text-align: center;
  color: #333;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-text {
  font-family: Paladins;
  font-size: 30 px;
}

.btn {
  font-family: 'Paladins';
}

a.navbar-brand {
  font-family: 'Paladins';
}

h2.combine {
  font-family: 'Paladins';
  color: white;
  text-align: center;
}

.text-input {
  visibility: hidden;
}

p.main-text {
  color: white;
  text-align: center;
  font-family: 'Paladins';

}

.imageGrid {
  text-align: center;
  font-family: 'Paladins';
}